.form {
    --font-color: #fff;
    --bg-color: #000;
    --main-color: #3e8ef8;
    padding: 30px;
    background: linear-gradient(to right, black 0%, grey 100%);
    display: flex;
    flex-direction: column;
     align-items: center; /* Centers child elements horizontally */
    justify-content: center;
    gap: 20px;
    border-radius: 25px;
    box-shadow: 0 6px 10px #3e8ef8;
  
    /* Add these properties */
    max-width: 100%; /*     Adjust the value as needed */
    width: 100%;
    margin: 0 auto; /* Centers the form horizontally */
  }
  
  .title {
    color: #fff; /* Changed to white */
    font-family: "Raleway", sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 1px;
    margin-bottom: 25px;
  }
  
  .title span {
    color: #fff; /* Changed to white */
    opacity: 0.8;
    font-weight: 400;
    font-size: 15px;
    display: block;
    text-transform: none;
    margin-top: 5px;
  }
  
  .input {
    width: 100%;
    height: 45px;
    border-radius: 25px;
    border: none;
    background-color: rgba(255, 255, 255, 0.1);
    font-family: "Raleway", sans-serif;
    font-size: 15px;
    font-weight: 500;
    color: #fff; /* Changed to white */
    padding: 0 20px;
    outline: none;
    transition: all 0.5s linear;
  }
  
  .input::placeholder {
    color: #fff; /* Changed to white */
    opacity: 0.6;
  }
  
  .input:focus {
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0 3px 6px #3e8ef8; /* Same as button */
  }
  
  textarea.input {
    padding: 15px 20px;
  }
  
  .button-confirm {
    font-family: "Raleway", sans-serif;
    text-transform: uppercase;
    color: #fff;
    background: linear-gradient(to right, black 0%, grey 100%);
    padding: 14px 34px;
    letter-spacing: 1px;
    margin: 20px auto 0 auto;
    margin-top: 45px;
    font-size: 15px;
    font-weight: 500;
    border-radius: 25px;
    transition: all 0.5s linear;
    border: 0;
    box-shadow: 0 6px 10px #3e8ef8; /* Same as button */
    cursor: pointer;
  }
  
  .button-confirm:hover {
    filter: brightness(140%);
  }
  
  .login-with {
    display: flex;
    gap: 20px;
    margin: 20px auto 0;
  }
  
  .button-log {
    font-family: "Raleway", sans-serif;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: none;
    background: linear-gradient(to right, black 0%, grey 100%);
    color: #fff;
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.5s linear;
    box-shadow: 0 6px 10px #3e8ef8; /* Same as button */
    cursor: pointer;
  }
  
  .button-log:hover {
    filter: brightness(140%);
  }
  
  /* Additional styles for the contact section */
  #contact {
    padding: 80px 0;
    background-color: white;
  }
  
  /* Make form responsive */
  @media (min-width: 768px) {
    .form {
      max-width: 100%;
      margin: 0 auto;
    }
  }

  .hours-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 20px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 25px;
    color: #fff;
    font-family: "Raleway", sans-serif;
    font-size: 15px;
    transition: all 0.3s ease;
  }
  
  .hours-row:hover {
    background-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0 3px 6px rgba(62, 142, 248, 0.3);
  }
  
  .day {
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
  
  .time {
    opacity: 0.8;
  }
  
  .hours-note {
    margin-top: 20px;
    text-align: center;
    color: #fff;
    opacity: 0.8;
    font-size: 14px;
    font-style: italic;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .form {
      width: 100%;
    }
    
    .hours-row {
      padding: 10px 15px;
      font-size: 14px;
    }
    
  }

  @media (max-width: 768px) {
    .hours-section {
      display: none;
    }
  }
