.where-to-find-me {
  margin-top: 60px;
  clear: both;
  padding-top: 20px;
  border-top: 1px solid white;
}

.about-row {
  display: flex;
  align-items: stretch;
}

.about-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 600px) {
  .about-row {
    flex-direction: column;
  }


  .who-am-i-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60% !important;  /* Fixed width */
    height: 60% !important; /* Fixed height to ensure perfect circle */
    border-radius: 50% !important;
    overflow: hidden !important;
    transform: translateY(8vh);
    object-fit: cover;
    mask-image: radial-gradient(circle, black 70%, transparent 90%);
    -webkit-mask-image: radial-gradient(circle, black 70%, transparent 90%);
  }

  
}